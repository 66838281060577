(function () {
    angular.module('informaApp')
        .service('ChartViewMapper', ChartViewMapper);

    function ChartViewMapper() {
        return {
            mapSelectedItemsForExport(selectedItems) {
                return selectedItems.map(x => {
                    return {
                        phaseDurations: x.phaseDurations,
                        name: x.name,
                        loa: mapSourceToPhasesObject(x.loa.source),
                        pos: mapSourceToPhasesObject(x.pos.source),
                        duration: mapSourceToPhasesObject(x.durations.source),
                        medianDuration: mapSourceToPhasesObject(x.medianDurations.source),
                        numberOfTransitions: mapSourceToPhasesObject(x.transitions.source)
                    };
                });
            }
        };
    }

    function mapSourceToPhasesObject([phase1, phase2, phase3, ndabla]) {
        return {
            phase1: phase1.total,
            phase2: phase2.total,
            phase3: phase3.total,
            ndabla: ndabla.total
        };
    }
})();